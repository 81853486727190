@import '~antd/dist/antd.less';

.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.p {
  margin-bottom: 0em !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .ant-table table {
    font-size: 0.625rem;
  }
  .nothingToLabel {
    font-size: 0.75rem;
  }

  .promo-container {
    width: 50vh !important;
  }
  .main-container .main {
    margin-right: 0;
  }
  .cards-layout {
    padding-top: 0;
  }
  .cards-wrapper .wrapper {
    padding: 0;
    padding-top: 3.125rem;
  }
  .cards-wrapper .wrapper img {
    width: 6.25rem;
  }
  .cards-layout .nav-container {
    height: 28vh;
  }
  .cards-layout .promo-container {
    width: 100vw !important;
  }
  .promo-container .big-text-white {
    font-size: 2.5rem;
    line-height: 2.188rem;
  }
  .promo-container .big-text-red {
    font-size: 2.5rem;
    line-height: 2.188rem;
  }
  .cards-wrapper .wrapper .title {
    font-size: 1rem;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }
}

/* Data Prep Factory CSS Annotator*/
.ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
}
.anticon.anticon-warning {
  text-align: center;
  width: 100%;
}
.anticon.anticon-warning svg {
  height: 6.25rem;
  width: 6.25rem;
}
.ant-modal-confirm-content {
  font-size: 1.125rem !important;
  padding-top: 9.375rem;
}
.ant-modal-confirm-btns .ant-btn {
  margin: 0rem 4.375rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.dpfSidelist .ant-list-pagination {
  text-align: center;
}

@btn-primary-bg: #81CDBA;@btn-primary-color: #FFFFFF;@btn-font-weight: 600;